<template>

    <div>
        <b-card no-body class="mb-0">

            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="5" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>{{ $t('Show') }}</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50" />
                        <label>{{ $t('entries') }}</label>
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-button variant="outline-warning" v-if="isRedirectionRouteActive"
                            @click="goToRedirectionPath()">
                            {{ $t('Go Back to Course Detail') }}
                        </b-button>
                    </b-col>
                    <!-- Search -->
                    <b-col cols="12" md="4">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1"
                                :placeholder="$t('Search...')" />
                        </div>
                    </b-col>
                </b-row>

            </div>

            <b-table ref="refUserListTable" class="position-relative" :items="fetchSurveyQuestions" responsive
                :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
                :empty-text="$t('No matching records found')" :sort-desc.sync="isSortDirDesc">
                <template #head()="data">
                    <span class="text">{{ $t(data.label.toUpperCase()) }}</span>
                </template>

                <template #cell(questionType)="data">
                    <span class="text">{{ $t(data.item.questionType) }}</span>
                </template>

                <template #cell(questionAnswers)="data">

                    <b-list-group v-if="data.item.questionType === 'SINGLE_SELECTION'">
                        <b-list-group-item v-for="(item, index) in JSON.parse(data.item.questionAnswers)" :key="index"
                            :variant="resolveSingleSelectionVariant(item, data)">
                            {{ (item.answer) }}
                        </b-list-group-item>
                    </b-list-group>

                    <b-list-group v-if="data.item.questionType == 'MULTIPLE_SELECTION'">
                        <b-list-group-item v-for="(item, index) in JSON.parse(data.item.questionAnswers)" :key="index"
                            :variant="resolveMultiSelectionVariant(item, data.item.memberAnswers)">
                            {{ item.answer }}
                        </b-list-group-item>
                    </b-list-group>

                    <b-list-group v-if="data.item.questionType === 'YES_NO_QUESTION'">
                        <b-list-group-item v-for=" (item, index) in JSON.parse(data.item.questionAnswers)" :key="index"
                            :variant="item.value == data.item.memberAnswer ? data.item.memberAnswer == data.item.acceptedAnswer ? 'success' : 'danger' : 'light'">
                            {{ item.answer }}
                        </b-list-group-item>
                    </b-list-group>
                    <b-list-group v-if="data.item.questionType === 'OPEN_ENDED_QUESTION'">
                        <b-list-group-item
                            :variant="data.item.progress > 0 ? data.item.isCorrect ? 'success' : 'danger' : 'light'">{{
                                data.item.memberOpenEndedAnswer
                            }}</b-list-group-item>
                    </b-list-group>
                </template>

                <template #cell(status)="data">
                    <div v-if="data.item.questionType === 'OPEN_ENDED_QUESTION'">
                        <div>
                            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-info"
                                @click="handleAnswer(data.item, true)">
                                <feather-icon icon="CheckIcon" />
                                <span class="align-middle ml-50">{{ $t("Accept Answer") }}</span>
                            </b-button>

                            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger"
                                v-if="!(data.item.deleted)" @click="handleAnswer(data.item, false)">

                                <feather-icon icon="XIcon" />
                                <span class="align-middle ml-50">{{ $t("Reject Answer") }}</span>
                            </b-button>
                        </div>
                    </div>


                </template>
            </b-table>
            <div class="my-2 ml-2">
                <b-row>

                    <!-- Pagination -->
                    <b-col> <b-button variant="success" @click="completeExam">{{ $t('Complete Exam')
                            }}</b-button></b-col>


                </b-row>

            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BListGroup, BListGroupItem
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useMembersLearningDetail'
import userStoreModule from '../memberLearningStoreModule'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useRedirectionRoute } from '@/@core/comp-functions/routing/useRedirectionRoute'
export default {

    computed: {

    },

    methods: {




    },
    data() {
        return {
            selectedQuestion: null
        }
    },

    components: {

        BListGroup, BListGroupItem,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
        ToastificationContent
    }, directives: {
        Ripple,
    },
    setup() {
        const USER_APP_STORE_MODULE_NAME = 'app-members-learning'

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })

        const isAddNewUserSidebarActive = ref(false)

        const roleOptions = [
            { label: 'Admin', value: 'admin' },
            { label: 'Author', value: 'author' },
            { label: 'Editor', value: 'editor' },
            { label: 'Maintainer', value: 'maintainer' },
            { label: 'Subscriber', value: 'subscriber' },
        ]

        const getPageroute = (coursemode, courseguid) => {
            let routename = ""
            if (coursemode === 'IN-PERSON') routename = 'course-sessions'
            else if (coursemode === 'ONLINE') routename = 'course-lectures'
            else if (coursemode === 'HYBRID') routename = 'course-lectures'
            else routename = 'course-lectures'

            const result = { name: routename, params: { id: courseguid } }
            return result
        }
        const getStatus = status => {
            // DRAFT
            if (status === '0') return 'secondary'
            // published
            if (status === '100') return 'success'

            // ended by time limit
            if (status === '200') return 'warning'
            // stopped
            if (status === '500') return 'warning'
            return 'secondary'
        }
        const getStatusText = status => {
            // DRAFT
            if (status === '0') return 'Draft'
            // published
            if (status === '100') return 'Published'

            // ended by time limit
            if (status === '200') return 'Ended'
            // stopped
            if (status === '500') return 'Stopped'
            return 'Draft'
        }


        const planOptions = [
            { label: 'Basic', value: 'basic' },
            { label: 'Company', value: 'company' },
            { label: 'Enterprise', value: 'enterprise' },
            { label: 'Team', value: 'team' },
        ]

        const statusOptions = [
            { label: 'Pending', value: 'pending' },
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
        ]

        let surveyId = router.currentRoute.params.id;

        if (surveyId) {
            store.commit('app/SETACTIVEUNIT', surveyId)
        } else {
            surveyId = store.getters['app/currentUnit']
        }

        const {
            fetchSurveyQuestions,
            tableColumns,
            perPage,
            currentPage,
            totalUsers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            refetchData,
            examData,
            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,
            resolveUserStatusVariantText,
            resolveSurveyStatusVariant,
            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
            resolvePageroute,
            resolveCourseContent,
            resolveSurveyStatus, totalQuestions,
        } = useUsersList(surveyId)
        const { goToRedirectionPath, isRedirectionRouteActive } = useRedirectionRoute()

        const handleAnswer = (question, answerResult) => {
            const params = { qid: question.id, userguid: question.clientmemberguid, answerresult: answerResult }
            console.log(params)
            store.dispatch("app-members-learning/processUserExam", params).then(e => refetchData()).catch(e => console.log(e))
        }
        const completeExam = () => {
            const questionData = examData.value[0]
            console.log(questionData)
            const params = {
                exid: questionData.subscriptionexamid,
                userguid: questionData.clientmemberguid,
                phcode: questionData.examguid,
                courseguid: questionData.courseguid,
            }
            store.dispatch("app-members-learning/completeuserexam", params).catch(e => console.log(e))
        }
        const resolveSingleSelectionVariant = (item, data) => {
            if (item.value === data.item.acceptedAnswer && data.item.acceptedAnswer !== data.item.memberAnswerValue) {
                return "info"
            }
            if (item.value !== data.item.memberAnswerValue) {
                return 'light'
            }
            else if (item.value === data.item.memberAnswerValue && data.item.acceptedAnswer === data.item.memberAnswerValue) {
                return "success"
            }
            return 'danger'
        }
        const resolveMultiSelectionVariant = (item, data) => {
            const memberAnswers = JSON.parse(data)
            console.log()
            if (item.selected && memberAnswers.includes(item.value)) {
                return 'success'
            }
            else if (item.selected && !memberAnswers.includes(item.value)) {
                return 'info'
            }
            else if (!item.selected && !memberAnswers.includes(item.value)) {
                return 'light'
            }
            return 'danger'
        }

        return {

            // Sidebar
            resolveSingleSelectionVariant,
            resolveMultiSelectionVariant,
            isAddNewUserSidebarActive,
            handleAnswer,
            fetchSurveyQuestions,
            tableColumns,
            perPage,
            currentPage,
            totalUsers,
            dataMeta,
            perPageOptions,
            goToRedirectionPath,
            completeExam,
            isRedirectionRouteActive,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            refetchData,

            // Filter
            avatarText,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,
            resolveUserStatusVariantText,
            resolveSurveyStatus,
            resolveSurveyStatusVariant,

            roleOptions,
            planOptions,
            statusOptions,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
            resolvePageroute,
            resolveCourseContent,
            totalQuestions,
            getPageroute,
            getStatus,
            getStatusText,
        }
    },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
