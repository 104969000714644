import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    fetchMemberRecords(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getUserLearningRecords`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchUserExamQuestions(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getuserexamquestions/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    processUserExam(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/processexamquestion', { qid: params.qid, userguid: params.userguid, answerresult: params.answerresult })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    completeuserexam(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/completeuserexam', {
            phcode: params.phcode,
            userguid: params.userguid,
            exid: params.exid,
            courseguid: params.courseguid
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
